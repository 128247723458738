<script setup>
import {ref, computed, onMounted} from 'vue';
import axios from "@/axios";
import {cityList, locationType} from '@/utils/utils';
import {showNotify, showSuccessToast} from "vant";
import {useStore} from "vuex";

const store = useStore();
const selectedCity = computed(() => store.getters.getSelectedCity);
const selectedRegion = computed(() => store.getters.getSelectedRegion);

const name = ref('');
const address = ref('');
const cover = ref([]);

const onSubmit = async () => {
  const formData = new FormData();
  formData.append('region', selectedRegion.value)
  formData.append('name', name.value)
  formData.append('address', address.value)
  formData.append('longitude', selectedLng.value)
  formData.append('latitude', selectedLat.value)
  formData.append('type', selectTypeCode.value)
  if (cover.value && cover.value[0]) {
    formData.append("file", cover.value[0].file)
  }

  try {
    loading.value = true;
    const response = await axios.post('/location', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    loading.value = false;
    showSuccessToast('提交成功，待审核');
    onClear();
  } catch (error) {
    console.error('Error:', error);
  }
};

const onClear = () => {
  searchQuery.value = '';
  selectedLng.value = ref(null);
  selectedLat.value = ref(null);
  name.value = '';
  combinedLatLng.value = '';
};

const selectType = ref('');
const selectTypeCode = ref('');
const showPicker = ref(false);
const showResults = ref(false);
const showArea = ref(false);
const loading = ref(false);

const onTypeConfirm = ({selectedOptions}) => {
  selectType.value = selectedOptions[0]?.text;
  selectTypeCode.value = selectedOptions[0]?.value;
  showPicker.value = false;
};


const mapContainer = ref(null);
const selectedLng = ref(null);
const selectedLat = ref(null);
const combinedLatLng = ref('');
let map = null;
let marker = null;
const searchQuery = ref('');
const searchResults = ref([]);

onMounted(() => {
  if (window.TMap) {
    // 初始化定位服务
    const geolocation = new qq.maps.Geolocation("HWFBZ-EIQKZ-KIFXO-ZSBY6-A6ABO-BAB2W", "gopark");

    // 获取用户当前位置
    geolocation.getLocation((position) => {
      const {lat, lng, city} = position;

      selectedCity.value = city;

      // 设置地图中心点为用户所在城市
      map = new TMap.Map(mapContainer.value, {
        center: new TMap.LatLng(lat, lng),
        zoom: 8,
        disableDefaultUI: true,
      });

      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

      // 初始化标记
      marker = new TMap.MultiMarker({
        map: map,
        geometries: []
      });

      // 添加地图点击事件监听
      map.on('click', (event) => {
        const {latLng} = event;
        selectedLng.value = latLng.lng;
        selectedLat.value = latLng.lat;
        combinedLatLng.value = `${selectedLng.value.toFixed(4)}, ${selectedLat.value.toFixed(4)}`;

        // 更新标记位置
        marker.setGeometries([{
          position: new TMap.LatLng(latLng.lat, latLng.lng),
        }]);
      });

    }, (err) => {
      console.error(err);
      alert('定位失败，请检查权限设置');
    }, {timeout: 8000});
  } else {
    console.error('腾讯地图 API 加载失败');
  }
});

const onSearch = async () => {
  if (selectedRegion.value === '100000') {
    showNotify({type: 'danger', position: 'bottom', message: '请选择具体城市，不要使用全国'});
    return
  }

  if (searchQuery.value.trim()) {
    const response = await axios.get('/cityQuery', {
      params: {
        city: selectedCity.value,
        keyword: searchQuery.value.trim(),
      },
    });
    searchResults.value = response.data.data || [];
    showResults.value = true;
  } else {
    searchResults.value = [];
  }
};

const onResultClick = (result) => {
  console.log('Selected result:', result.location.lat, result.location.lng);
  if (map) {
    map.setZoom(18);
    map.setCenter(new TMap.LatLng(result.location.lat, result.location.lng));
    marker.setGeometries([{
      position: new TMap.LatLng(result.location.lat, result.location.lng),
      content: '点击地图可移动标记点',
    }]);
    selectedLng.value = result.location.lng;
    selectedLat.value = result.location.lat;
    name.value = result.title;
    combinedLatLng.value = `${selectedLng.value.toFixed(4)}, ${selectedLat.value.toFixed(4)}`;
  }
  showResults.value = false;
}

const onAreaConfirm = async ({selectedValues, selectedOptions, selectedIndexes}) => {
  console.log(selectedOptions)
  store.commit('setSelectedCity', selectedOptions[1].text);
  store.commit('setSelectedRegion', selectedOptions[1].value);

  try {
    const response = await axios.get('/cityCenter', {
      params: {
        code: selectedRegion.value
      },
    });

    console.log(response.data.data.lat);
    console.log(response.data.data.lng);
    map.setZoom(8);
    map.setCenter(new TMap.LatLng(response.data.data.lat, response.data.data.lng));

  } catch (error) {
    console.error("Error fetching data: ", error);
  }

  showArea.value = false;
};

</script>

<template>
  <van-nav-bar title="新增地点"/>
  <van-overlay :show="loading">
    <div class="wrapper">
      <van-loading type="spinner" color="#0094ff" size="24px" vertical>
        数据加载中...
      </van-loading>
    </div>
  </van-overlay>
  <div ref="mapContainer" class="map-container"></div>

  <div class="search-container">
    <form action="/">
      <van-search
          v-model="searchQuery"
          show-action
          placeholder="请输入地点关键词进行搜索"
          @search="onSearch"
          clearable
          @clear="onClear"
      >
        <template #action>
          <div @click="onSearch">地图搜索</div>
        </template>
      </van-search>
    </form>
    <div v-if="showResults" class="search-results">
      <ul>
        <li v-for="result in searchResults" :key="result.id" @click="onResultClick(result)">
          {{ result.title }}
        </li>
      </ul>
    </div>
  </div>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field
          v-model="selectedCity"
          is-link
          readonly
          required
          name="area"
          label="地区选择"
          placeholder="点击选择省市区"

          @click="showArea = true"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <van-area
            :area-list="cityList"
            columns-num="2"
            @confirm="onAreaConfirm"
            @cancel="showArea = false"
        />
      </van-popup>

      <van-field
          v-model="selectType"
          is-link
          readonly
          name="picker"
          label="类型"
          placeholder="点击选类型"
          required
          @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
            :columns="locationType"
            @confirm="onTypeConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
          v-model="name"
          name="地点名称"
          label="地点名称"
          placeholder="地点名称"
          required
          :rules="[{ required: true, message: '请填写地点名称' }]"
      />

      <van-field
          v-model="address"
          name="具体位置"
          label="具体位置"
          placeholder="填写具体位置"
      />

      <van-field v-model="combinedLatLng" required label="经纬度" placeholder="在上方的地图中进行标记" readonly
                 class="half-field"/>

      <van-field name="uploader" label="地点照片">
        <template #input>
          <van-uploader v-model="cover" :max-count="1"/>
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>

</template>

<style scoped>

.map-container {
  width: 100%;
  height: 30vh;
}

.coordinates {
  display: flex;
  justify-content: space-between;
}

.half-field {
  flex: 1;
  margin-right: 5px;
}

.half-field:last-child {
  margin-right: 0;
}

.search-container {
  position: relative;
}


.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}

.search-results ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f5f5f5;
}

</style>