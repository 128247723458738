import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedCity: '全国',
    selectedRegion: '100000',
  },
  getters: {
    getSelectedCity: (state) => state.selectedCity,
    getSelectedRegion: (state) => state.selectedRegion,
  },
  mutations: {
    setSelectedCity(state, city) {
      state.selectedCity = city
    },
    setSelectedRegion(state, region) {
      state.selectedRegion = region
    },
  },
  actions: {
  },
  modules: {
  }
})
