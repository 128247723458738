<template>
  <a-table :columns="columns" :data-source="data">
<!--    <template #headerCell="{ column }">-->
<!--      <template v-if="column.key === 'name'">-->
<!--        <span>-->
<!--          <smile-outlined />-->
<!--          Name-->
<!--        </span>-->
<!--      </template>-->
<!--    </template>-->

    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'name'">
        <a>
          {{ record.name }}
        </a>
      </template>

      <template v-if="column.key === 'coverPath'">
        <a-image
            :width="100"
            :src="`${baseUrl}${record.coverPath}`"
        />
<!--        <img :src="`${baseUrl}${record.coverPath}`" alt="img" style="width: 50px; height: 50px;" />-->
      </template>
<!--      <template v-else-if="column.key === 'tags'">-->
<!--        <span>-->
<!--          <a-tag-->
<!--              v-for="tag in record.tags"-->
<!--              :key="tag"-->
<!--              :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"-->
<!--          >-->
<!--            {{ tag.toUpperCase() }}-->
<!--          </a-tag>-->
<!--        </span>-->
<!--      </template>-->
      <template v-else-if="column.key === 'action'">
        <span>
<!--          <a>Invite 一 {{ record.name }}</a>-->
          <a>修改信息</a>
          <a-divider type="vertical" />
          <a>删除</a>
          <a-divider type="vertical" />
<!--          <a class="ant-dropdown-link">-->
<!--            More actions-->
<!--            <down-outlined />-->
<!--          </a>-->
        </span>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import axios from "@/axios";
import {onMounted, ref} from "vue";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const data = ref([]);

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Image',
    dataIndex: 'coverPath',
    key: 'coverPath',
  },
  {
    title: '地址',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: '城市',
    key: 'region',
    dataIndex: 'region',
  },
  {
    title: 'Action',
    key: 'action',
  },
];

onMounted(() => {
  fetchData()
})

const fetchData = async (params = {}) => {
  try {
    // loading.value = true;
    const response = await axios.get('/admin/locations', {
      params: params  // 将传入的参数附加到请求中
    });
    if (response.status === 200) {
      console.log(response.data.data)
      data.value = response.data.data;
      // loading.value = false;
    }
  } catch (error) {
    console.log(error);
    // loading.value = false;
  }
};
</script>