<template>
  <div>
    <div class="main-layout">
      <router-view class="content"></router-view>
      <van-tabbar v-model="active" active-color="#1B72E8" class="tabbar">
        <van-tabbar-item icon="wap-home" replace to="/">首页</van-tabbar-item>
        <van-tabbar-item icon="fire-o" replace to="/comment">动态</van-tabbar-item>
        <van-tabbar-item icon="add-square" replace to="/add">新增</van-tabbar-item>
        <van-tabbar-item icon="location" replace to="/map">地图</van-tabbar-item>
        <!--        <van-tabbar-item icon="contact" replace to="/me">我的</van-tabbar-item>-->
      </van-tabbar>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
const active = ref(0);
</script>

<style scoped>
.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  height: 93vh;
}

.van-tabbar {
  --van-tabbar-height: 5vh;
}

</style>
