<template>
  <div>
    <h1>Login Page</h1>
    <!-- 在这里添加你的登录表单 -->
  </div>
</template>

<script>
export default {
  name: 'LoginView'
};
</script>

<style scoped>
</style>
